import React from 'react';
import reactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import styles from './wysiwyg.module.scss';
import { useStaticQuery, graphql } from 'gatsby';

const WYSIWYG = ({ children }) => {

  const data = useStaticQuery(graphql`
    query WYSIWYGQuery {
      site {
        siteMetadata {
          drupalURL
        }
      }
    }
  `);

  // Complete relative image URLs so wysiwyg embedded images work.
  const transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      const srcData = {
        'site': data.site.siteMetadata.drupalURL,
        'path': node.attribs['src']
      };

      // If the path exists and this IS a relative URL.
      // (i.e. /some/page vs https://some-page.com)
      if (srcData.path && srcData.path.match(/^\//)) {
        // Add the Drupal URl to the relative URL.
        node.attribs.src = srcData.site + srcData.path;
        return convertNodeToElement(node, index);
      }
    }
  };

  const options = {
    decodeEntities: true,
    transform
  };

  let bodyMarkup;
  if (typeof children.props !== 'undefined') {
    if (typeof children.props.dangerouslySetInnerHTML !== 'undefined') {
      bodyMarkup = reactHtmlParser(children.props.dangerouslySetInnerHTML.__html, options);
    }
  }
  else {
    bodyMarkup = children;
  }

  return (
    <div className={styles.wysiwyg}>
      {bodyMarkup}
    </div>
  );
};

export default WYSIWYG;
