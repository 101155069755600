// Object.assign polyfill for React Spring needed for IE11 support.
import 'core-js/fn/object/assign';
import React, { Component, Fragment } from 'react';
import { Spring, animated } from 'react-spring';
import PropTypes from 'prop-types';

import Button from '../button/button';
import Link from '../link/link';

import styles from './hamburger-menu.module.scss';

/**
 * The Dropdown component provides the interactive
 * functionality to show / hide some content triggered via click.
 *
 * Returns a <Button> and, if the button is clicked, the children
 * that were passed in.
 *
 * This component is like the generic Dropdown component, but includes the actual
 * menu items so we can set aria-hidden / tabindex based on the state of this menu.
 */
export default class HamburgerAccordion extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.setState(
      {
        open: !this.state.open
      },
      () => {
        document.addEventListener('click', this.closeMenu);
      }
    );
  }

  closeMenu() {
    this.setState({ open: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  dropdownContent() {
    return (
      <Spring
        native
        from={{ height: 0 }}
        to={{ height: this.state.open ? 'auto' : 0 }}
      >
        {(props) => (
          <animated.div
            aria-hidden={this.state.open ? false : true}
            className={styles.item}
            style={props}
          >
            <ul>
              {this.props.links &&
                this.props.links.map((left) => (
                  <li className={styles.subMenuItem} key={left.text}>
                    <Link
                      tabIndex={this.state.open ? 0 : -1}
                      onClick={this.closeMenu}
                      className={styles.subMenuLink}
                      to={left.link}
                    >
                      <span>{left.text}</span>
                    </Link>
                  </li>
                ))}
            </ul>
          </animated.div>
        )}
      </Spring>
    );
  }

  render() {
    return (
      <Fragment>
        <Button
          {...this.props}
          onClick={this.handleClick}
          aria-expanded={this.state.open || 'false'}
        >
          {this.props.buttonContent}
        </Button>
        {this.dropdownContent()}
      </Fragment>
    );
  }
}

HamburgerAccordion.propTypes = {
  animate: PropTypes.bool,
  buttonContent: PropTypes.node.isRequired,
  links: PropTypes.array.isRequired
};
