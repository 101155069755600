import React, { Component } from 'react';
import Modal from 'react-modal';

// Custom components.
import Button from '../button/button';
import Icon from '../icons/icon';
import SearchForm from '../search-form/search-form';

import styles from './search.module.scss';

// Tell react-modal where to position the Modal in the DOM
// so it can hide the rest of the content when the modal is open.
Modal.setAppElement('#___gatsby');

export default class Search extends Component {
  constructor() {
    super();

    this.state = {
      open: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ open: true }, () => {
      // Adds the class just after state is set to open to prevent jumpiness.
      document.querySelector('body').classList.add(styles.bodyModalOpen);
    });
  }

  closeModal() {
    // Removes the class just before state is set to close to prevent jumpiness.
    document.querySelector('body').classList.remove(styles.bodyModalOpen);
    this.setState({ open: false });
  }

  componentWillUnmount() {
    // Removes the class when the component unmounts.
    document.querySelector('body').classList.remove(styles.bodyModalOpen);
  }

  componentDidUpdate(prevProps, prevState) {
    // If this component updates and the modal was ALREADY open
    // AND it's STILL open, something is likely wrong and we should
    // close the modal.
    //
    // A reason this could happen is if someone uses the search and
    // they click a result but they're already on the same page as
    // the result they clicked.
    if (this.state.open === true && prevState.open === true) {
      this.closeModal();
    }
  }

  render() {
    // React-modal will accept a single string as a class
    // or an object with these three properties to facilitate
    // CSS Transitions.
    const classnames = {
      base: styles.searchModal,
      afterOpen: styles.afterOpen,
      beforeClose: styles.beforeClose
    };

    return (
      <React.Fragment>
        <Button
          aria-expanded={this.state.open}
          borderStyle="square"
          className={styles.searchOpenButton}
          color="white"
          onClick={this.openModal}
          size="small"
        >
          <Icon className={styles.searchIcon} icon="search" focusable="false" />
        </Button>
        <Modal
          className={classnames}
          closeTimeoutMS={100}
          contentLabel="Search this site"
          isOpen={this.state.open}
          onRequestClose={this.closeModal}
        >
          <div className={styles.modalWrap}>
            <SearchForm className={styles.searchForm} />
            <Icon
              className={styles.siteLogo}
              fill="#fff"
              height="113"
              icon="citysprings"
              width="180"
            />
          </div>
          <Button
            aria-label="Close search"
            className={styles.submitButton}
            onClick={this.closeModal}
          >
            <Icon icon="close" fill="#fff" height="26" width="26" />
          </Button>
        </Modal>
      </React.Fragment>
    );
  }
}
