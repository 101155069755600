import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Custom components.
import Button from '../button/button';

// Styles.
import styles from './mega-menu.module.scss';

/**
 * The Dropdown component provides the interactive
 * functionality to show / hide some content triggered via click.
 *
 * Returns a <Button> and, if the button is clicked, the children
 * that were passed in.
 */
export default class Dropdown extends Component {
  constructor() {
    super();
    this.state = {
      openMenu: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  // Clean up the event listener when the page changes.
  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  handleClick(e) {
    e.preventDefault();

    this.openMenu();
  }

  openMenu() {
    this.setState(
      {
        openMenu: true
      },
      () => {
        document.addEventListener('click', this.closeMenu);
      }
    );
  }

  closeMenu() {
    this.setState({ openMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const buttonClasses = classnames([styles.button], {
      [styles.buttonActive]: this.state.openMenu
    });

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          aria-expanded={this.state.openMenu || 'false'}
          className={buttonClasses}
          borderStyle="square"
          color={false}
          textStyle="upper"
          type="button"
        >
          {this.props.buttonContent}
        </Button>
        {this.state.openMenu ? this.props.children : null}
      </Fragment>
    );
  }
}

Dropdown.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};
