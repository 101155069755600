import React, { Component } from 'react';
import throttle from 'lodash/throttle';

// Custom compontents.
import { default as Dropdown } from './mega-menu-dropdown';
import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import MegaMenuContext from '../mega-menu-context/mega-menu-context';
import MenuPanel from '../menu-panel/menu-panel';
import { finitePromotionTypes, filterExpiredPromotions, get } from '../../helpers/hooks-helpers';

// Data for the menu.
import data from './data.json';

// Styles.
import styles from './mega-menu.module.scss';

export default class MegaMenu extends Component {
  constructor() {
    super();

    // The MegaMenu breaks to the HamburgerMenu at this breakpoint.
    this.breakpoint = 1210;

    this.state = {
      isMega: false
    };

    this.handleResize = throttle(this.handleResize.bind(this), 500);
    this.removeExpiredContent = this.removeExpiredContent.bind(this);
  }

  componentDidMount() {
    this.setState({
      isMega: window.innerWidth > this.breakpoint
    });

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    // Remove body .fixed when resizing from small to large while
    // the hamburger menu is open.
    if (this.state.isMega) {
      document.querySelector('body').classList.remove('fixed');
    }
  }

  // Displays the MegaMenu vs. the HamburgerMenu depending on viewport width.
  handleResize() {
    if (window.innerWidth > this.breakpoint) {
      this.setState({ isMega: true });
    }
    else {
      this.setState({ isMega: false });
    }
  }

  // Takes all promoted events and returns one non-expired event.
  removeExpiredContent(content) {
    // Return the first non-expired promoted content.
    const promotedContent = (content || []).find(item =>
      finitePromotionTypes.has(item.__typename) && filterExpiredPromotions(item)
    );
    return promotedContent || '';
  }

  render() {
    if (this.state.isMega) {
      return (
        <MegaMenuContext.Consumer>
          {(menuData) => (
            <nav aria-label="Main Menu" className={styles.megaMenu}>
              <ul className={styles.menuItems}>
                {data.menuItems.map((panel, index) => (
                  <li key={panel?.title} className={styles.menuItem}>
                    <Dropdown buttonContent={panel.title}>
                      <MenuPanel
                        left={panel.left}
                        right={
                          this.removeExpiredContent(
                            get(menuData, `${index}.node.relationships.field_promoted_content`, [])
                          )
                        }
                        panelDirection={panel.panelDirection}
                      />
                    </Dropdown>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </MegaMenuContext.Consumer>
      );
    }
    return <HamburgerMenu />;
  }
}
