import React from 'react';
import Link from '../link/link';

import { ReactComponent as CitySprings } from './assets/city-springs-logo.svg';

import styles from './site-logo.module.scss';

const SiteLogo = (props) => (
  <Link {...props} aria-label="Go to City Springs home page" to="/">
    <CitySprings className={styles.siteLogo} />
  </Link>
);

export default SiteLogo;
