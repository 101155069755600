import React from 'react';
import classnames from 'classnames';

// Custom components.
import Button from '../button/button';
import Icon from '../icons/icon';
import Link from '../link/link';

// Component styles.
import styles from './menu-panel.module.scss';

const MenuPanel = ({ left, right, panelDirection }) => (
  <div
    className={classnames(
      styles.panel,
      // If there are only 3 items, make it a two column.
      // If not, compute the necessary amount of columns based on nav items.
      styles['iconColumns' + ((left.length/2).toFixed(0))],
      {
        [styles.positionRight]: panelDirection ? panelDirection : false
      }
    )}
  >
    <div className={styles.panelLeft}>
      {left && left.map((item, index) => <PanelLeft item={item} key={index} />)}
    </div>
    {right && (
      <div className={styles.panelRight}>
        <PanelRight
          img={right.relationships.field_image.localFile.childImageSharp.menu}
          alt={right.field_image.alt}
          type={right.internal.type}
          title={right.title}
          body={
            right.internal.type === 'node__event' && right.date ?
              right.date.format ?
                right.date.format :
                right.date.full :
              null
          }
          url={right.path && right.path.alias}
          cta={right.cta}
        />
      </div>
    )}
  </div>
);


export default MenuPanel;

const PanelRight = ({ img, alt, title, body, url, type }) => (
  <React.Fragment>
    <img className={styles.panelRightImage} {...img} alt={alt} />
    <div>
      <p className={styles.panelTitle}>{title}</p>
      <div className={styles.panelBody}>{body}</div>
    </div>
    <Button color="blueLight" to={url}>
      {title && ctaText(type, title)}
    </Button>
  </React.Fragment>
);

const PanelLeft = ({ item }) => (
  <div className={styles.iconBlock}>
    <Link to={item.link} className={styles.iconLink}>
      <Icon icon={item.icon} />
      <div className={styles.iconLinkText}>{item.text}</div>
    </Link>
  </div>
);

const ctaText = (type) => {
  let name = false;

  switch (type) {
  case 'node__place':
    name = 'Discover';
    break;
  case 'node__news':
    name = 'Learn More';
    break;
  case 'node__event':
    name = 'View Event';
    break;
  case 'node__page':
    name = 'Learn More';
    break;
  }

  return name;
};
