import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// Import a polyfill for node.contains and object.values specifically for IE11.
import './document-contains.polyfill';
import 'core-js/fn/object/values';
import OutsideClickHandler from 'react-outside-click-handler';

import Button from '../button/button';

/**
 * The Dropdown component provides the interactive
 * functionality to show / hide some content triggered via click.
 *
 * Returns a <Button> and, if the button is clicked, the children
 * that were passed in.
 */
export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.childCloseMenu = this.childCloseMenu.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.setState({ showMenu: !this.state.showMenu });
  }

  closeMenu(event) {
    // This check solves for an edge case where the button that toggles the dropdown
    // is clicked _through_ the OutsideClickHandler. We want to make sure
    // the close menu isn't setting the state twice, once on handleClick and
    // again here.
    if (event && event.target.id !== this.props.id) {
      this.setState({ showMenu: false });
    }
  }

  // Allow click events of nested children to close the menu.
  //
  // An example of this would be when a dropdown menu has links to content,
  // clicking the links would bubble the click event up and close the menu.
  // This way if the link that's clicked is on the current page the dropdown
  // is closed.
  childCloseMenu(event) {
    // Make sure we're only closing the dropdown if a link was clicked.
    if (event.target.tagName === 'A' || event.target.type === 'submit') {
      this.closeMenu(event);
    }
  }

  render() {
    // Pull out the variables we need.
    const { children, buttonContent, id, ...buttonAttributes } = this.props;

    return (
      <Fragment>
        <Button
          {...buttonAttributes}
          id={id}
          onClick={this.handleClick}
          aria-expanded={this.state.showMenu ? 'true' : 'false'}
        >
          {buttonContent}
        </Button>

        {this.state.showMenu && (
          <OutsideClickHandler onOutsideClick={this.closeMenu}>
            <div onClickCapture={this.childCloseMenu}>{children}</div>
          </OutsideClickHandler>
        )}
      </Fragment>
    );
  }
}

Dropdown.propTypes = {
  buttonContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired
};
