import React from 'react';

const { Provider, Consumer } = React.createContext();

const MegaMenuContext = {
  Provider,
  Consumer
};

export default MegaMenuContext;
