import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Check Drupal metatag for robots settings, otherwise use the default.
const shouldIndex = ({ metatags, noindex }) => {
  let robots;
  if (metatags && metatags.robots) {
    robots = metatags.robots;
  }
  else if (noindex) {
    robots = 'nofollow, noindex';
  }
  return robots;
};

// Return a formatted title.
const formatTitle = ({ title, siteConfig, metatags }) => {
  // Provide a default metatag title.
  // Example, if title is provided "Featured events" the default title will be:
  // "Featured events | City Springs"
  // If NO title is provided the default title will be:
  // "City Springs"
  const defaultTitle = title
    ? `${title} | ${siteConfig.title}`
    : siteConfig.title;

  // Provide a detailed metatag title if it's provided from Drupal.
  // Example, if meta title is set in Drupal for an event "Monthly BBQ" the detailed title will be:
  // "Monthly BBQ | City Springs"
  const detailedTitle = metatags.title
    ? `${metatags.title} | ${siteConfig.title}`
    : false;

  return detailedTitle || defaultTitle;
};

// Return default meta image or default image.
const formatImage = ({ siteConfig, image }) => {
  let src = `${siteConfig.siteUrl}/images/site-default-image.png`;
  let alt = '';

  // Make sure both the image and image source exist.
  if (image && image.src) {
    src = siteConfig.siteUrl + image.src;
    alt = image.alt || '';
  }

  return {
    src,
    alt
  };
};

const SEO = ({
  title,
  description,
  noindex,
  siteConfig,
  pageUrl,
  image,
  ...metatags
}) => {
  const robots = shouldIndex({ metatags, noindex });
  const formattedTitle = formatTitle({ title, siteConfig, metatags });
  const contentImage = formatImage({ siteConfig, image });

  return (
    <Helmet>
      <html lang="en-US" />
      {/* General tags */}
      <title>{formattedTitle}</title>
      {description && <meta name="description" content={description} />}
      <link rel="icon" type="image/svg" href="/images/city-springs.svg" />
      {/* OpenGraph tags */}
      {pageUrl && (
        <meta property="og:url" content={siteConfig.siteUrl + pageUrl} />
      )}
      {/* If the content has metatags defined in Drupal it's an article. */}
      {metatags.title && <meta property="og:type" content="article" />}
      <meta property="og:title" content={formattedTitle} />
      {/* Need facebook description here if possible */}
      <meta property="og:description" content={description} />
      <meta property="og:image" content={contentImage.src} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@citysprings" />
      <meta name="twitter:title" content={formattedTitle} />
      {/* Need twitter description here if possible. */}
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={contentImage.src} />
      {contentImage.alt && (
        <meta name="twitter:image:alt" content={contentImage.alt} />
      )}

      {/* Prevent search engines from crawling a page. */}
      {robots && <meta name="robots" content={robots} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // Setting noindex: true will prevent search engines from crawling a page.
  noindex: PropTypes.bool,
  siteConfig: PropTypes.object.isRequired,
  pageUrl: PropTypes.string,
  image: PropTypes.object
};

SEO.defaultProps = {
  noindex: false
};

export default SEO;
