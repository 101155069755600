import { useStaticQuery, graphql } from 'gatsby'

export const useInformationData = () => {
  const { allNodeInformation } = useStaticQuery(
    graphql`
      query InformationData {
        allNodeInformation(
          filter: { status: { eq: true }, description: {} }
          sort: { fields: [field_order, drupal_internal__nid], order: ASC }
        ) {
          nodes {
            drupal_id
            svg {
              content
            }
            title
            field_number
            description {
              value
            }
            hyperlink {
              title
              uri
              options {
                attributes {
                  target
                }
              }
            }
            field_background_color
            relationships {
              field_promotion {
                drupal_internal__tid
                name
              }
            }
          }
        }
      }
    `
  )

  const information = allNodeInformation.nodes.map((item) => {
    const promotions = {}
    item.relationships.field_promotion.forEach(
      (promotion) =>
        (promotions[promotion.drupal_internal__tid] = promotion.name)
    )

    return {
      id: item.drupal_id,
      title: item.title,
      description: item?.description?.value,
      number: item?.field_number,
      icon: item?.svg?.content,
      backgroundColor: item?.field_background_color,
      hyperlink: {
        title: item?.hyperlink?.title,
        uri: item?.hyperlink?.uri,
        target: item?.hyperlink?.options?.attributes?.target ?? '_self'
      },
      promotions: promotions
    }
  })

  return information
}
