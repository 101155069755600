import React from 'react';

import HeaderTopMenu from '../header-top-menu/header-top-menu';
import MegaMenu from '../mega-menu/mega-menu';
import SiteLogo from '../site-logo/site-logo';
import SocialLinks from '../social-links/social-links';

import styles from './header.module.scss';

const Header = ({ data }) => (
  <header className={styles.header}>
    <div className={styles.headerTop}>
      <SocialLinks className={styles.socialLinks} />
      <HeaderTopMenu data={data} />
    </div>
    <div className={styles.headerBottom}>
      <div className={styles.headerBottomWrap}>
        <SiteLogo className={styles.headerLogo} />
        <MegaMenu data={data} />
      </div>
    </div>
  </header>
);

export default Header;
