import React, { Component } from 'react';
import { Index } from 'elasticlunr';

import { Consumer } from '../search-context/search-context';
import Icon from '../icons/icon';
import Link from '../link/link';

import styles from './search-form.module.scss';

export default class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      results: [],
      loading: false,
      // didSearch helps display the no results text only if the user has
      // triggered a search.
      didSearch: false
    };

    this.getOrCreateIndex = this.getOrCreateIndex.bind(this);
    this.searchText = this.searchText.bind(this);
    this.submitForm = this.submitForm.bind(this);
    // Create a reference for the search textbox.
    this.searchRef = React.createRef();
  }

  getOrCreateIndex(siteSearchIndex) {
    return this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
      Index.load(siteSearchIndex.index);
  }

  searchText(query, SearchData) {
    this.index = this.getOrCreateIndex(SearchData);
    // Query the index with search string to get an [] of IDs
    const results = this.index
      .search(query, SearchData)
      // Map over each ID and return the full document
      .map(({ ref }) => this.index.documentStore.getDoc(ref));
    this.setState({
      query,
      results,
      loading: false,
      // If there are results, reset didSearch, otherwise leave it the same.
      didSearch: results.length > 0 ? false : this.state.didSearch
    });
  }

  submitForm(event, SearchData) {
    // Prevent the default form submit.
    event.preventDefault();

    // Grab the search input element.
    const searchInput = this.searchRef.current.value;

    // Show loading icon.
    this.setState({ loading: true });

    // Delay the form submission so the user knows something
    // actually happened when they clicked the submit button.
    setTimeout(() => {
      // Use the current value in the search input to perform the search.
      this.setState({ didSearch: true }, () =>
        this.searchText(searchInput, SearchData)
      );
    }, 1000);
  }

  render() {
    return (
      <Consumer>
        {(SearchData) => (
          <form
            onSubmit={(event) => this.submitForm(event, SearchData)}
            className={styles.form}
            method="post"
          >
            <label htmlFor="siteSearch" className={styles.searchLabel}>
              Search Citysprings.com
            </label>
            <input
              className={styles.input}
              id="siteSearch"
              type="search"
              value={this.state.query}
              placeholder="what are you looking for?"
              ref={this.searchRef}
              onChange={(event) =>
                this.searchText(event.target.value, SearchData)
              }
            />
            {this.state.results &&
              !this.state.loading && (
              <ul className={styles.results}>
                {this.state.results.map((page) => (
                  <li key={`result-${page.id}`}>
                    <p>
                      <Link className={styles.resultsLink} to={page.path}>
                        {page.title}
                      </Link>
                    </p>
                  </li>
                ))}
              </ul>
            )}
            {this.state.loading && (
              <Icon
                icon="loader"
                height="50"
                width="50"
                className={styles.searchLoader}
              />
            )}
            {this.state.results.length < 1 &&
              !this.state.loading &&
              this.state.didSearch && <p>Sorry, no results found.</p>}
          </form>
        )}
      </Consumer>
    );
  }
}
