import moment from 'moment-timezone';
import { get } from 'lodash';

const finitePromotionTypes = new Set([
  'node__event', 'node__news', 'node__page', 'node__place',
]);

const filterExpiredNodes = (node) => {
  return filterExpiredPromotions(get(node, 'node', node))
};

const filterExpiredPromotions = (node) => {
  if (get(node, 'field_private_event', false)) {
    return false;
  }
  // If the node's __typename property is set but it is not listed in the set of
  // finite promotion types, we don't have to worry about expiring this promo.
  const type = get(node, '__typename');
  if (type && !finitePromotionTypes.has(type)) {
    return true;
  }
  for (const field of ['field_date', 'date']) {
    if (get(node, [field, 'removalTime'])) {
      const removalTime = moment(node[field].removalTime);
      const currentTime = moment();
      return currentTime.isBefore(removalTime);
    }
  }
  return false;
};

export {
  get,
  finitePromotionTypes,
  filterExpiredNodes,
  filterExpiredPromotions,
}
