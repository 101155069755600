const menuItems = [
  {
    parent: {
      title: 'Tickets & Events',
      link: '/events',
      color: 'blue',
      hideOnMobile: false
    },
    children: []
  },
  {
    parent: {
      title: 'Plan Your Visit',
      link: '',
      color: 'blueMedium',
      hideOnMobile: false
    },
    children: [
      {
        title: 'Getting to us',
        link: '/plan-your-visit/getting-to-us'
      },
      {
        title: 'Map of City Springs',
        link: '/plan-your-visit/map-of-city-springs'
      },
      {
        title: 'Parking',
        link: '/plan-your-visit/parking'
      },
      {
        title: 'Dining',
        link: '/dining'
      },
      {
        title: 'Places to Stay',
        link: '/places-to-stay'
      },
      {
        title: 'Accessibility',
        link: '/plan-your-visit/accessibility'
      }
    ]
  },
  {
    parent: {
      title: 'Contact Us',
      link: '/contact-us',
      hideOnMobile: true
    }
  }
];

export default menuItems;
