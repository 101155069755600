import React from 'react';

// Available Icons.
import { ReactComponent as CitySprings } from './svg/city-springs.svg';
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as Drink } from './svg/drink.svg';
import { ReactComponent as Instagram } from './svg/Instagram.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Loader } from './svg/loader.svg';
import { ReactComponent as ExploreCitySprings } from './svg/explore-city-springs/10-explore-city-springs.svg';
import { ReactComponent as Theaters } from './svg/explore-city-springs/11-theatres.svg';
import { ReactComponent as ConferenceCenter } from './svg/explore-city-springs/12-conference-center.svg';
import { ReactComponent as OutDoorVenues } from './svg/explore-city-springs/13-outdoor-venues.svg';
import { ReactComponent as HeritagePark } from './svg/explore-city-springs/14-heritage-park.svg';
import { ReactComponent as RestaurantsAndRetail } from './svg/explore-city-springs/15-restaurants-and-retail.svg';
import { ReactComponent as CityHall } from './svg/explore-city-springs/16-city-hall.svg';
import { ReactComponent as ArtAndHistory } from './svg/art-and-history/20-art-and-history.svg';
import { ReactComponent as GalleryExhibits } from './svg/art-and-history/21-gallery-exhibits.svg';
import { ReactComponent as PublicArt } from './svg/art-and-history/22-public-art.svg';
import { ReactComponent as HistoryMuseum } from './svg/art-and-history/23-history-museum.svg';
import { ReactComponent as KidsAndFamilies } from './svg/kids-and-families/30-kids-and-families.svg';
import { ReactComponent as EducationalPrograms } from './svg/kids-and-families/31-educational-programs.svg';
import { ReactComponent as FarmersMarket } from './svg/farmers-market/40-farmers-market.svg';
import { ReactComponent as DiscoverTheMarket } from './svg/farmers-market/41-discover-the-market.svg';
import { ReactComponent as OurVendors } from './svg/farmers-market/42-our-vendors.svg';
import { ReactComponent as LiveEntertainment } from './svg/farmers-market/43-live-entertainment.svg';
import { ReactComponent as VendorServices } from './svg/farmers-market/44-vendor-services.svg';
import { ReactComponent as RentOurSpaces } from './svg/rent-our-spaces/50-rent-our-spaces.svg';
import { ReactComponent as WeddingsAndParties } from './svg/rent-our-spaces/51-weddings-and-parties.svg';
import { ReactComponent as MeetingsAndConferences } from './svg/rent-our-spaces/52-meetings-and-conferences.svg';
import { ReactComponent as PerformingArtsRentals } from './svg/rent-our-spaces/53-performing-arts-rentals.svg';
import { ReactComponent as Festivals } from './svg/rent-our-spaces/54-festivals.svg';
import { ReactComponent as ServicesAndCatering } from './svg/rent-our-spaces/55-services-and-catering.svg';
import { ReactComponent as RentalProcedures } from './svg/rent-our-spaces/56-rental-procedures.svg';
import { ReactComponent as TechnicalInformation } from './svg/rent-our-spaces/57-technical-information.svg';
import { ReactComponent as BookingRequests } from './svg/rent-our-spaces/58-booking-requests.svg';
import { ReactComponent as SupportTheArts } from './svg/support-the-arts/60-support-the-arts.svg';
import { ReactComponent as Memberships } from './svg/support-the-arts/61-memberships.svg';
import { ReactComponent as IndividualGiving } from './svg/support-the-arts/62-individual-giving.svg';
import { ReactComponent as CorporateSponsorships } from './svg/support-the-arts/63-corporate-sponsorships.svg';
import { ReactComponent as VolunteerOpportunities } from './svg/support-the-arts/64-volunteer-oportunities.svg';
import { ReactComponent as AboutCreateCitySprings } from './svg/about-city-springs/70-about-create-city-springs.svg';
import { ReactComponent as AboutUs } from './svg/about-city-springs/72-about-us.svg';
import { ReactComponent as ContactUs } from './svg/about-city-springs/74-contact-us.svg';
import { ReactComponent as KeepingYouSafe } from './svg/about-city-springs/75-keeping-you-safe.svg';


const Icon = (props) => {
  switch (props.icon) {
  case 'citysprings':
    return <CitySprings {...props} />;
  case 'close':
    return <Close {...props} />;
  case 'drink':
    return <Drink {...props} fill="#fff" />;
  case 'instagram':
    return <Instagram {...props} />;
  case 'search':
    return <Search {...props} />;
  case 'loader':
    return <Loader {...props} fill="#fff" />;
  case 'exploreCitySprings':
    return <ExploreCitySprings {...props} fill="#fff" height="45" />;
  case 'theaters':
    return <Theaters {...props} fill="#fff" width="67" />;
  case 'conferenceCenter':
    return <ConferenceCenter {...props} fill="#fff" width="65" />;
  case 'outdoorVenues':
    return <OutDoorVenues {...props} fill="#fff" width="62" />;
  case 'heritagePark':
    return <HeritagePark {...props} fill="#fff" width="66" />;
  case 'restaurantsAndRetail':
    return <RestaurantsAndRetail {...props} fill="#fff" width="59" />;
  case 'cityHall':
    return <CityHall {...props} fill="#fff" width="82" />;
  case 'artAndHistory':
    return <ArtAndHistory {...props} fill="#fff" height="45" />;
  case 'galleryExhibits':
    return <GalleryExhibits {...props} fill="#fff" width="72" />;
  case 'publicArt':
    return <PublicArt {...props} fill="#fff" width="53" />;
  case 'historyMuseum':
    return <HistoryMuseum {...props} fill="#fff" width="86" />;
  case 'kidsAndFamilies':
    return <KidsAndFamilies {...props} fill="#fff" height="45" />;
  case 'educationalPrograms':
    return <EducationalPrograms {...props} fill="#fff" width="60" />;
  case 'farmersMarket':
    return <FarmersMarket {...props} fill="#fff" height="45" />;
  case 'discoverTheMarket':
    return <DiscoverTheMarket {...props} fill="#fff" width="55" />;
  case 'ourVendors':
    return <OurVendors {...props} fill="#fff" width="60" />;
  case 'liveEntertainment':
    return <LiveEntertainment {...props} fill="#fff" width="70" />;
  case 'vendorServices':
    return <VendorServices {...props} fill="#fff" width="43" />;
  case 'rentOurSpaces':
    return <RentOurSpaces {...props} fill="#fff" height="45" />;
  case 'weddingsAndParties':
    return <WeddingsAndParties {...props} fill="#fff" width="62" />;
  case 'meetingsAndConferences':
    return <MeetingsAndConferences {...props} fill="#fff" width="66" />;
  case 'performingArtsRentals':
    return <PerformingArtsRentals {...props} fill="#fff" width="77" />;
  case 'festivals':
    return <Festivals {...props} fill="#fff" width="74" />;
  case 'servicesAndCatering':
    return <ServicesAndCatering {...props} fill="#fff" width="60" />;
  case 'rentalProcedures':
    return <RentalProcedures {...props} fill="#fff" width="43" />;
  case 'technicalInformation':
    return <TechnicalInformation {...props} fill="#fff" width="59" />;
  case 'bookingRequests':
    return <BookingRequests {...props} fill="#fff" width="84" />;
  case 'supportTheArts':
    return <SupportTheArts {...props} fill="#fff" height="45"/>;
  case 'memberships':
    return <Memberships {...props} fill="#fff" width="56" />;
  case 'individualGiving':
    return <IndividualGiving {...props} fill="#fff" width="71" />;
  case 'corporateSponsorships':
    return <CorporateSponsorships {...props} fill="#fff" width="77" />;
  case 'volunteerOpportunities':
    return <VolunteerOpportunities {...props} fill="#fff" width="35" />;
  case 'aboutCreateCitySprings':
    return <AboutCreateCitySprings {...props} fill="#fff" height="45"  />;
  case 'aboutUs':
    return <AboutUs {...props} fill="#fff" width="55" />;
  case 'contactUs':
    return <ContactUs {...props} fill="#fff" width="70" />;
  case 'keepingYouSafe':
    return <KeepingYouSafe {...props} fill="#fff" width="59" />;
  default:
    return null;
  }
};

export default Icon;
