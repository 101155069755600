import React from 'react';
import { Markup } from 'react-render-markup';
import { useFooterMenuData } from '../../hooks/use-footer-menu-data';
import { useInformationData } from '../../hooks/use-information-data';

// Custom components.
import Button from '../button/button';
import Link from '../link/link';
import SocialLinks from '../social-links/social-links';

// Icons
import { ReactComponent as Explore } from './assets/Explore.svg';
import { ReactComponent as Logo } from './assets/city-springs.svg';
import { ReactComponent as Phone } from './assets/Phone.svg';
import { ReactComponent as Time } from './assets/Time.svg';

// Styles.
import styles from './footer.module.scss';

// Menu data.
import data from './data.json';

// The container for the footer.
const Footer = () => (
  <footer className={styles.footer} aria-label="Site Footer">
    <div className={styles.footerWrap}>
      <FooterInfo />
      <FooterNav />
      <FooterCta />
      <Link
        to="/"
        aria-label="City Springs Homepage"
        className={styles.footerLogo}
      >
        <Logo fill="#fff" width="180" height="113" />
      </Link>
    </div>
  </footer>
);

// The info column for the footer.
const FooterInfo = () => {
  const contact = useInformationData()
    // 269 is the term ID associated with the 'CitySprings Footer: City Springs
    // Box Office' promotion term in Drupal to make sure we're limiting the data
    // to Footer content only.
    .filter((item) => item.promotions[269]);
  return (
    <div className={styles.footerInfo}>
      <h2 className={styles.footerTitle}>{data.title}</h2>
      <ul>
        {contact.map((item) => (
          <li className={styles.infoItem} key={item.id}>
            <div className={styles.icon}>
              <Markup markup={item.icon} />
            </div>
            <div className={styles.footerMenuDescription}>
              {(item?.hyperlink?.uri && (
                <Link
                  to={item.hyperlink.uri}
                  target={item?.hyperlink?.target ?? '_self'}
                >
                  <Markup markup={item.description} />
                </Link>
              )) || <Markup markup={item.description} />}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// The navigation column in the footer.
const FooterNav = () => {
  const footerMenu = useFooterMenuData();
  return (
    <nav aria-label="Footer menu" className={styles.footerNav}>
      <ul>
        {footerMenu.map((link) => (
          <li key={link.id}>
            <Link to={link.uri}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

// The newsletter CTA column in the footer.
const FooterCta = () => {
  const cta = useInformationData()
    // 270 is the term ID associated with the 'CitySprings.com Footer: Button'
    // promotion term in Drupal to make sure we're limiting the data to Footer
    // button content only.
    .filter((item) => item.promotions[270])
    .shift() ?? {};
  return (
    <div className={styles.footerCta}>
      {cta?.description && <Markup markup={cta.description} /> || (
        <p className={styles.footerCtaText}>
          {data.ctaColumnTitle}
        </p>
      )}
      <Button
        className={styles.cta}
        color="white"
        textStyle="upper"
        to={cta?.hyperlink?.uri || data.ctaUrl}
      >
        {cta?.hyperlink?.title || data.cta}
      </Button>
      <SocialLinks
        className={styles.footerSocial}
        fill="#fff"
        height="30"
        width="30"
      />
    </div>
  )
};

export default Footer;
