import { useStaticQuery, graphql } from 'gatsby';

export const useFooterMenuData = () => {
  const { allMenuLinkContentMenuLinkContent } = useStaticQuery(
    graphql`
      query MyQuery {
        allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: {eq: "citysprings-com-footer"},
            enabled: {eq: true}
          }
        ) {
          nodes {
            id
            link {
              uri
            }
            title
          }
        }
      }
    `
  );

  const menuItems = allMenuLinkContentMenuLinkContent.nodes.map(item => ({
    id: item.id,
    name: item.title,
    uri: item.link.uri,
  }));

  return menuItems;
};
